<template>
  <div
    ref="image"
    class="text-options-wrapper"
    :class="{ 'format-block': !isFormat }"
  >
    <div v-if="!isFormat" class="flex-wrapper">
      <h3 class="format-block-title">
        {{
          isStory
            ? $t("create.step3.textStory")
            : $t("create.step3.buttonSelector.thumbnailText")
        }}
      </h3>
    </div>
    <div class="button-inputs" :class="{ 'bulk-button-inputs': isBulk }">
      <div v-if="!isFormat" class="text-form">
        <label>{{ $t("create.step3.text") }}</label>
        <input
          :value="textOptions.text || currentVideo.videoTitle"
          :maxlength="isStory ? 20 : 1000"
          class="text-input --with-borders smallInput"
          type="text"
          @input="
            onTextChange({
              inputFor: 'text',
              value: $event.target.value,
            })
          "
        />
      </div>

      <div v-if="isFormat" class="styles-form">
        <label>{{ $t("create.step3.buttonSelector.textStyle") }}</label>
        <div class="style-inputs" :class="{ 'bulk-style-inputs': isBulk }">
          <div class="text-styles">
            <button
              class="button-input"
              :class="{ selected: formatTextOptions.isBold }"
              @click="toggleButton(`isBold`)"
            >
              <Bold></Bold>
            </button>
            <button
              class="button-input"
              :class="{ selected: formatTextOptions.isItalic }"
              @click="toggleButton(`isItalic`)"
            >
              <Italic></Italic>
            </button>
            <button
              class="button-input"
              :class="{ selected: formatTextOptions.isUnderlined }"
              @click="toggleButton(`isUnderlined`)"
            >
              <Underlined></Underlined>
            </button>
          </div>
          <div v-if="!isStory" class="position-wrapper">
            <div class="position-styles" :class="{ 'bulk-flex': isBulk }">
              <button
                class="button-input"
                :class="{ selected: formatTextOptions.position === 'end' }"
                @click="
                  onTextOptionsChange({
                    inputFor: 'position',
                    value: 'end',
                  })
                "
              >
                <PositionBottom></PositionBottom>
              </button>
              <button
                class="button-input"
                :class="{ selected: formatTextOptions.position !== 'end' }"
                @click="
                  onTextOptionsChange({
                    inputFor: 'position',
                    value: 'start',
                  })
                "
              >
                <PositionTop></PositionTop>
              </button>
            </div>
            <button class="button-input wider" @click="showModal = true">
              <Left></Left
              ><svg
                class="accordion-icon"
                :class="{ isOpen: showModal }"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 16 10"
                aria-hidden="true"
              >
                <path
                  d="M15 1.2l-7 7-7-7"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            v-if="showModal"
            v-click-outside="hideModal"
            class="align-modal"
            :class="{ 'bulk-align-modal': isBulk }"
          >
            <div
              class="align-button"
              @click="
                onTextOptionsChange({
                  inputFor: 'placement',
                  value: 'left',
                })
              "
            >
              <button
                class="button-input"
                :class="{
                  selected:
                    !formatTextOptions.placement ||
                    formatTextOptions.placement == 'left',
                }"
              >
                <Left></Left>
              </button>
              {{ $t("shared.toggleBox.left") }}
            </div>

            <div
              class="align-button"
              @click="
                onTextOptionsChange({
                  inputFor: 'placement',
                  value: 'center',
                })
              "
            >
              <button
                class="button-input"
                :class="{ selected: formatTextOptions.placement == 'center' }"
              >
                <Center></Center>
              </button>

              {{ $t("shared.toggleBox.center") }}
            </div>

            <div
              class="align-button"
              @click="
                onTextOptionsChange({
                  inputFor: 'placement',
                  value: 'right',
                })
              "
            >
              <button
                class="button-input"
                :class="{ selected: formatTextOptions.placement == 'right' }"
              >
                <Right></Right>
              </button>
              {{ $t("shared.toggleBox.right") }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="isFormat" class="input-color">
        <label v-if="!isStory" class="box-title">{{
          $t("create.step3.buttonSelector.textColorAndSize")
        }}</label>
        <label v-else class="box-title">
          {{ $t("create.step3.buttonSelector.textColor") }}</label
        >
        <div class="buttons-color">
          <div v-if="!isStory" class="size-button">
            <input
              type="number"
              class="size-input"
              min="12"
              max="30"
              :value="
                formatTextOptions.size === undefined
                  ? defaultTextOptions.size
                  : formatTextOptions.size
              "
              content="size"
              @focusout="validateCarouselInputSize"
              @input="
                onTextOptionsChange({
                  inputFor: 'size',
                  value: $event.target.value,
                })
              "
            />
            <span
              >px
              <TooltipIcon
                v-tippy="{
                  arrow: true,
                  placement: 'right',
                  a11y: false,
                  boundary: 'window',
                }"
                class="tooltip-icon"
                :content="$t('create.step3.buttonSelector.carouselTooltip')"
            /></span>
          </div>
          <div v-else class="size-button">
            <input
              type="number"
              class="size-input"
              min="10"
              max="14"
              :value="
                formatTextOptions.size === undefined
                  ? defaultTextOptions.size
                  : formatTextOptions.size
              "
              content="size"
              @focusout="validateStoriesInputSize"
              @input="
                onTextOptionsChange({
                  inputFor: 'size',
                  value: $event.target.value,
                })
              "
            />
            <span
              >px
              <TooltipIcon
                v-tippy="{
                  arrow: true,
                  placement: 'right',
                  a11y: false,
                  boundary: 'window',
                }"
                class="tooltip-icon"
                :content="$t('create.step3.buttonSelector.storiesTooltip')"
            /></span>
          </div>
          <button
            class="value-color"
            :style="`background-color:${
              formatTextOptions.color || defaultTextOptions.color
            }`"
            @click="showPicker(`color`)"
          />
        </div>
      </div>
      <div v-if="!isStory && isFormat" class="input-color">
        <label class="box-title">{{
          $t("create.step3.buttonSelector.backgroundColor")
        }}</label>
        <div class="buttons-color">
          <button
            class="value-color"
            :style="`background-color:${
              formatTextOptions.backgroundColor || 'transparent'
            }`"
            @click="showPicker(`backgroundColor`)"
          />
        </div>
      </div>
    </div>
    <div
      v-if="displayPicker"
      key="color-picker"
      v-click-outside="hidePicker"
      class="vidjet-color-picker"
    >
      <colorpicker
        :value="
          formatTextOptions[pickerType] ||
          (pickerType == 'color' ? 'black' : 'FFFFFF5E')
        "
        @input="onPickerChange($event, pickerType)"
      />
    </div>
  </div>
</template>

<script>
import Bold from "@/assets/svg/text-options/text-bold.svg?inline";
import Italic from "@/assets/svg/text-options/text-italic.svg?inline";
import Underlined from "@/assets/svg/text-options/text-underlined.svg?inline";
import PositionBottom from "@/assets/svg/text-options/position-bottom.svg?inline";
import PositionTop from "@/assets/svg/text-options/position-top.svg?inline";
import Left from "@/assets/svg/text-options/left.svg?inline";
import Center from "@/assets/svg/text-options/center.svg?inline";
import Right from "@/assets/svg/text-options/right.svg?inline";
import { Chrome } from "vue-color";
import { mapMutations } from "vuex";
import TooltipIcon from "@/assets/svg/tooltip-icon-dark.svg?inline";
import { mapGetters } from "vuex";

export default {
  components: {
    colorpicker: Chrome,
    Bold,
    Italic,
    Underlined,
    PositionBottom,
    PositionTop,
    Left,
    Center,
    Right,
    TooltipIcon,
  },
  props: {
    textOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
    formatTextOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isStory: {
      type: Boolean,
      default: false,
    },
    isBulk: {
      type: Boolean,
      default: false,
    },
    currentIndex: {
      type: Number,
      default: null,
    },
    currentFeedEditIndex: {
      type: Number,
      default: null,
    },
    isFormat: {
      type: Boolean,
      default: false,
    },
    campaignFormatOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
    currentVideo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      displayPicker: false,
      pickerType: "",
      showModal: false,
      defaultTextOptions: {
        size: this.isStory ? 12 : 16,
        color: "black",
      },
    };
  },
  computed: {
    ...mapGetters({
      feedList: "campaign/feedList",
    }),
    formatObject() {
      return this.campaignFormatOptions;
    },
  },
  watch: {
    currentIndex() {
      this.copyTextOptionsFromPrevious();
    },
    currentFeedEditIndex() {
      this.copyTextOptionsFromPrevious();
    },
  },
  created() {
    this.ensureDefaultSize();
  },
  methods: {
    // store from player
    ...mapMutations({
      setPlayerText: "videos/SET_TEXT_OPTIONS_PREVIEW",
    }),

    hidePicker() {
      this.displayPicker = false;
    },
    showPicker(type) {
      this.pickerType = type;
      this.displayPicker = true;
    },
    onPickerChange(e, name) {
      this.$emit("change-text-object", {
        inputFor: "textOptions",
        value: { ...this.formatTextOptions, [name]: e.hex8 },
      });
      this.setPlayerText({ ...this.formatTextOptions, [name]: e.hex8 });
    },
    toggleButton(name) {
      let value = !this.formatTextOptions[name];
      this.$emit("change-text-object", {
        inputFor: "textOptions",
        value: { ...this.formatTextOptions, [name]: value },
      });
      this.setPlayerText({ ...this.formatTextOptions, [name]: value });
    },
    onTextChange(e) {
      this.$emit("change-text-object", {
        ...this.textOptions,
        [e.inputFor]: e.value,
      });
      this.setPlayerText({ ...this.textOptions, [e.inputFor]: e.value });
    },
    onTextOptionsChange(e) {
      const updatedOptions = {
        ...this.formatTextOptions,
        [e.inputFor]: e.value,
      };
      this.$emit("change-text-object", {
        inputFor: "textOptions",
        value: updatedOptions,
      });

      this.setPlayerText(updatedOptions);
    },
    hideModal() {
      this.showModal = false;
    },
    validateCarouselInputSize() {
      let value;
      if (this.formatTextOptions.size === "") {
        value = 16;
      } else if (this.formatTextOptions.size > 30) {
        value = 30;
      } else if (this.formatTextOptions.size < 12) {
        value = 12;
      }
      if (value) this.onTextOptionsChange({ inputFor: "size", value });
    },
    validateStoriesInputSize() {
      let value;
      if (this.formatTextOptions.size === "") {
        value = 12;
      } else if (this.formatTextOptions.size > 14) {
        value = 14;
      } else if (this.formatTextOptions.size < 10) {
        value = 10;
      }
      if (value) this.onTextOptionsChange({ inputFor: "size", value });
    },
    ensureDefaultSize() {
      if (this.formatTextOptions.text && !this.formatTextOptions.size) {
        this.onTextOptionsChange({
          inputFor: "size",
          value: this.defaultTextOptions.size,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button-inputs {
  margin: 0 auto;
  width: 100%;
  text-align: left;
  display: grid;
  gap: 16px;
  @include font-small;
  color: $dark-grey;
  .button-input {
    border-radius: 4px;
    height: 32px;
    display: flex;
    width: 32px;
    align-items: center;
    justify-content: center;
    &.selected {
      background: $dark-purple;
      path {
        fill: white;
      }
    }
    &.wider {
      width: 42px !important;
    }
  }
}

.text-form,
.styles-form {
  .bulk-style-inputs {
    display: grid;
    gap: 4px;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    justify-content: unset;
    .text-styles {
      grid-column: span 3;
    }

    .position-styles {
      grid-column: 1 / 3;
      grid-row: 2;
    }

    .button-input {
      grid-column: 2 / 3;
      grid-row: 2 / 2;
    }
  }
}
.position-wrapper {
  display: flex;
  justify-content: space-between;
}
.text-form,
.styles-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.style-inputs {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
  position: relative;
  .text-styles {
    display: flex;
    gap: 4px;
  }
  .position-styles {
    display: flex;
    gap: 4px;
  }
}

.size-button {
  margin-right: 16px;
  display: flex;
  align-items: center;
}
.size-input {
  margin-right: 4px;
  height: 24px;
  width: 30px;
}
.align-modal {
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.25);
  padding: 16px;
  z-index: 99;
  background: white;
  position: absolute;
  right: 0;
  bottom: -10px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.bulk-align-modal {
  right: 32px;
}

.align-button {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}
.isOpen {
  transform: rotate(180deg);
  transition: all 0.1s;
}
.accordion-icon {
  transition: all 0.1s;
  width: 13px;
  height: 7px;
}
.flex-wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
}
.interrogation-mark {
  border: solid 1px #585858;
  border-radius: 50%;
  transform: scale(1.1);
}
</style>
