<template>
  <section class="campaign-format-wrapper">
    <!-- START SETTINGS -->
    <device-tabs
      v-if="!isEmbed && !isCarousel"
      :chosen-device="chosenDevice"
      @update-device="onUpdateDevice"
    />
    <div class="selections">
      <InstructionBanner
        v-if="isEmbed"
        selected-name="thibault"
        :title-text="$t('create.step3.instructionBanner.title')"
        :link-text="$t('create.step3.instructionBanner.button')"
        :link-href="instructionBannerLink"
      />

      <div
        ref="scrollToBottom"
        :class="['selection', { isInactive: !campaignAllowedOn[chosenDevice] }]"
      >
        <div v-if="!isEmbed && !isCarousel" :class="['enable-campaign']">
          <p>
            {{
              campaignAllowedOn[chosenDevice]
                ? $t("create.step3.enabledOnDevice")
                : $t("create.step3.disabledOnDevice")
            }}
          </p>
          <!-- for edit mode fill missing object with a watch property -->
          <vidjet-toggle-btn
            v-model="campaignAllowedOn[chosenDevice]"
            :checked="campaignAllowedOn[chosenDevice]"
            @input="emitFillObjectIfEmpty()"
          ></vidjet-toggle-btn>
        </div>
        <FormatTypePicker
          v-if="campaignAllowedOn[chosenDevice] && !isEmbed && campaignLoaded"
          :format-type="selectedFormatObject.formatType"
          :is-sticky-bar="isStickyBar"
          :is-story="isStory"
          :is-popup="isPopup"
          :chosen-device="chosenDevice"
          :campaign-format-options="selectedFormatObject"
          :is-bulk="Boolean(campaign.bulk)"
          @change-format-type="emitFormatTypeChange"
          @change-fullscreen="emitFormatObjectChange"
        />
        <FormatBlock
          v-if="campaignLoaded && campaignAllowedOn[chosenDevice]"
          :is-bulk="isBulk"
          :format-object="selectedFormatObject"
          :chosen-device="chosenDevice"
          :is-embed="isEmbed"
          :is-carousel="isCarousel"
          :is-carousel-inline="isCarouselInline"
          :is-story="isStory"
          :video-size="videoSize"
          :is-multiple="campaign.videos.length > 1"
          :current-plan="currentPlan"
          :campaign-title="selectedFormatObject.title"
          :format-type="selectedFormatObject.formatType"
          @change-format-object="emitFormatObjectChange"
          @video-title-change="emitVideoTitleChange"
        />
        <TitleBlock
          v-if="shouldShowTitle"
          :campaign-title="selectedFormatObject.title"
          :is-multiple="isMultiple"
          :format-type="selectedFormatObject.formatType"
          :is-bubble="isBubble"
          @campaign-title="emitVideoTitleChange"
        />
        <StickyBarBlock
          v-if="campaignAllowedOn[chosenDevice] && isStickyBar"
          :chosen-device="chosenDevice"
          :campaign-sticky-bar-option="selectedFormatObject.stickyBarOptions"
          @change-sticky-bar-object="emitStickyBarObjectChange"
        />
      </div>
    </div>
    <!-- END SETTINGS -->
  </section>
</template>

<script>
import VidjetToggleBtn from "../../shared/Vidjet-toggle-btn.vue";
import enums from "../../../enums";
import DeviceTabs from "./DeviceTabs.vue";
import FormatBlock from "./FormatBlock.vue";
import TitleBlock from "./TitleBlock.vue";
import StickyBarBlock from "./StickyBarBlock.vue";
import FormatTypePicker from "./FormatTypePicker.vue";

import campaignCreationMixin from "../campaignCreationMixin";
import InstructionBanner from "@/components/shared/InstructionBanner.vue";

import { Chrome } from "vue-color";

const { FormatType, Position, DeviceType } = enums;

export default {
  name: "CampaignFormatSettings",
  components: {
    VidjetToggleBtn,
    TitleBlock,
    DeviceTabs,
    FormatBlock,
    FormatTypePicker,
    StickyBarBlock,
    colorpicker: Chrome,
    InstructionBanner,
  },
  mixins: [campaignCreationMixin],
  beforeRouteEnter(t, f, next) {
    next(async (vm) => {
      vm.init();
    });
  },
  props: {
    campaign: {
      type: Object,
      default: () => {},
    },

    desktop: {
      type: Object,
      default: () => {},
    },
    mobile: {
      type: Object,
      default: () => {},
    },
    chosenDevice: {
      type: String,
      default: "desktop",
    },
    isCarousel: {
      type: Boolean,
      default: false,
    },
    isEmbed: {
      type: Boolean,
      default: false,
    },
    campaignAllowed: {
      type: Object,
      default: () => {},
    },
    currentPlan: {
      type: String,
      default: "free",
    },
    isBulk: {
      type: Boolean,
      default: false,
    },
    textOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      options: ["desktop", "mobile"],
      FormatType,
      DeviceType,
      Position,
      displayPicker: false,
      instructionBannerLink:
        "https://help.vidjet.io/en/articles/7060789-size-your-iframe-like-a-boss",
    };
  },

  computed: {
    isStickyBar() {
      return this.selectedFormatObject.isStickyBar;
    },

    isPopup() {
      return this.selectedFormatObject.formatType === 0;
    },

    campaignLoaded() {
      return Object.keys(this.selectedFormatObject).length > 0;
    },

    videoSize() {
      return {
        width: this.campaign?.videos[0]?.width,
        height: this.campaign?.videos[0]?.height,
      };
    },

    isPreviewDesktop() {
      return this.chosenDevice === "desktop";
    },

    selectedFormatObject() {
      return this.chosenDevice === "desktop" ? this.desktop : this.mobile;
    },

    isCarouselInline() {
      return (
        this.selectedFormatObject.formatType === FormatType.carousel &&
        !this.selectedFormatObject.isFullScreen
      );
    },

    campaignAllowedOn() {
      if (this.campaignAllowed) return this.campaignAllowed;
      return {
        desktop: false,
        mobile: false,
      };
    },

    isStory() {
      return (
        this.selectedFormatObject.formatType === FormatType.carousel &&
        this.selectedFormatObject.isStory
      );
    },
    isBubble() {
      return this.selectedFormatObject.formatType === FormatType.bubble;
    },
    isMultiple() {
      return this.campaign.videos.length > 1;
    },
    shouldShowTitle() {
      if (this.isBubble && !this.selectedFormatObject.hideThumbnailPlay)
        return false;
      if (!this.campaignAllowedOn[this.chosenDevice]) return false;
      if (this.isStickyBar || this.isCarousel || this.isEmbed) return false;
      if (
        this.selectedFormatObject.formatType == FormatType.popup &&
        this.isMultiple
      )
        return false;
      return true;
    },
  },

  methods: {
    onUpdateDevice(payload) {
      this.$emit("update-device", payload);
    },

    emitFormatObjectChange(payload) {
      this.$emit("format-object-change", payload);
    },

    emitVideoTitleChange(payload) {
      this.$emit("video-title-change", payload);
    },

    emitStickyBarObjectChange(payload) {
      this.$emit("stickybar-object-change", payload);
    },

    emitFormatTypeChange(payload) {
      this.$emit("format-type-change", payload);
    },

    // Enabling campaign on new device, fill it with copy of other device
    emitFillObjectIfEmpty() {
      this.$emit("fill-object-if-empty", this.campaignAllowedOn);
    },

    // soon to be removed when we allow multi video in all use cases
    emitChangeCtaObject(payload) {
      this.$emit("change-cta-object", payload);
    },

    // soon to be removed when we allow multi video in all use cases
    emitChangeImage(payload) {
      this.$emit("change-image", payload);
    },
    goPreviousStep() {
      this.$emit("previous-step");
    },
    goNextStep() {
      this.$emit("next-step");
    },
    editGoBack() {
      this.$emit("edit-go-back");
    },
    hidePicker() {
      this.$emit("border-color-changed", this.selectedFormatObject.borderColor);
      this.displayPicker = false;
    },
    onPickerChange(e) {
      this.selectedFormatObject.borderColor = e.hex8;
    },
  },
};
</script>

<style lang="scss" scoped>
$format-step-width: 40%;
$format-step-min-width: 400px;
$format-step-max-width: 525px;
.campaign-format-wrapper {
  overflow-y: auto;
}

.selections {
  padding-bottom: 16px;
}

.selection {
  width: 100%;
  margin: 16px 0px;
  display: grid;
  place-items: center;
  grid-gap: 16px;
}

.enable-campaign {
  display: flex;
  gap: 16px;
  align-items: center;
}
.deactivate-thumbnail {
  pointer-events: none;
  filter: grayscale(1);
}

.color-setting {
  width: 85%;
  padding: 0.5rem;
  justify-content: space-evenly;
  position: relative;
  text-align: center;
  &:last-child {
    margin-bottom: 12px;
  }
  @include frame;
}

.bulk-color-setting {
  &:last-child {
    margin-bottom: 120px;
  }
}

.color-setting-text {
  margin: 10px auto;
  @include font-normal;
  color: $dark-grey;
}

.value-color {
  margin: 15px auto;
}
</style>
