<template>
  <sliding-modal class="video-library-sliding-modal" @close="close">
    <div class="main-container">
      <VideoLibrary
        class="video-library"
        :show-buttons="false"
        :preselected-query="preselectedQuery"
        :selected-videos-prop="selectedVideos"
        @play-video-in-preview="selectVideoInPreview"
        @open-product-list-modal="openProductListModal"
        @are-videos-selected="handleSelectedVideos"
        @update:selectedVideos="handleSelectedVideosUpdate"
        @reset-selected-video="resetSelectedVideo"
      />
      <div class="preview-wrapper">
        <vidjet-preview
          :is-embed="true"
          :is-step2="true"
          :is-video-library-modal="true"
          :preview-campaign="selectedVideo"
        ></vidjet-preview>
      </div>
      <div class="video-menu">
        <div class="icons">
          <TrashIcon
            v-tippy="{ placement: 'top', arrow: true }"
            class="delete-video-icon icon"
            :class="{ 'selected-icon': areVideosSelected }"
            :content="
              $t('create.step2.videoLibraryFloatingBar.tooltips.delete')
            "
            @click="handleOpenDeleteVideosModal"
          />
          <DownloadIcon
            v-tippy="{ placement: 'top', arrow: true }"
            class="download-icon icon"
            :class="{ 'selected-icon': areVideosSelected }"
            :content="
              $t('create.step2.videoLibraryFloatingBar.tooltips.download')
            "
            @click="handleDownloadSelectedVideos"
          />
        </div>
        <div class="separation-line"></div>
        <div class="icons">
          <TitleIcon
            v-tippy="{ placement: 'top', arrow: true }"
            class="title-icon icon"
            :class="{ 'selected-icon': areVideosSelected }"
            :content="$t('create.step2.videoLibraryFloatingBar.tooltips.title')"
            @click="openTitleModal"
          />
          <TagProduct
            v-tippy="{ placement: 'top', arrow: true }"
            class="tag-product-icon icon"
            :class="{ 'selected-icon': areVideosSelected }"
            :content="
              $t('create.step2.videoLibraryFloatingBar.tooltips.tagProduct')
            "
            @click="openProductModal"
          />
          <PlusIcon
            v-tippy="{ placement: 'top', arrow: true }"
            class="add-widget-icon icon"
            :class="{ 'selected-icon': areVideosSelected }"
            :content="
              $t('create.step2.videoLibraryFloatingBar.tooltips.addWidget')
            "
            @click="openWidgetModal"
          />
        </div>
        <div class="separation-line"></div>
        <div class="buttons">
          <div
            class="upload-video-area"
            :style="uploadBlockBackground"
            :multiple="true"
            @dragover.prevent="changeBackground = true"
            @dragleave.prevent="changeBackground = false"
            @drop.prevent="onFileChanged"
            @click="$refs.fileInput.click()"
          >
            <section class="flex-centered">
              <button class="upload-button">
                {{ $t("create.step2.uploadZone.button") }}
              </button>
              <p class="upload-drop-text-floating">
                {{ $t("create.step2.uploadZone.orDrop2") }}
              </p>
            </section>
            <input
              id="fileUploaded"
              ref="fileInput"
              class="hidden"
              multiple
              type="file"
              accept="video/*"
              @change="onFileChanged"
            />
          </div>
          <google-picker
            class="quick-import-button"
            @fileInput="onFileChanged"
          ></google-picker>
          <!-- <button
            class="quick-import-button"
            @click="toggleImporterModal('youtube')"
          >
            <img
              :src="require('../../assets/youtube-logo.svg')"
              class="youtube-logo"
              alt="logo youtube"
            />
            <span>Youtube</span>
          </button> -->
        </div>
      </div>
      <PlanRegistration
        v-if="showPlanRegistrationModal"
        :new-plan="proposedPlan"
        :show-arrows="currentPlan !== 'scale'"
        @close="showPlanRegistrationModal = false"
      />
      <small-modal
        v-if="selectedImporter === 'youtube'"
        :fit-content="true"
        @close="removeVideos"
      >
        <YoutubeImporter
          :is-video-library="true"
          :is-loading="isLoading"
          @import-video="onFileChanged($event)"
          @remove-videos="removeVideos"
          @close="createVideosFromYoutube"
        />
      </small-modal>
      <DeleteVideosModal
        v-if="openDeleteVideosModal"
        :number-of-videos-selected="selectedVideos.length"
        :number-of-videos-selected-in-campaigns="
          numberOfSelectedVideosInCampaigns
        "
        :campaign-names-with-selected-videos="campaignNamesWithSelectedVideos"
        @close-delete-videos-modal="handleCloseDeleteVideosModal"
        @delete-selected-videos="deleteSelectedVideos"
      />
      <play-error-modal
        :show-modal="showErrorModal"
        @toggleModal="togglePlayErrorModal"
      ></play-error-modal>
      <AddToCartModal
        v-if="showProductsModal"
        class="add-to-cart-modal"
        :is-full-screen="true"
        :selected-products-edit="taggedProductsOnVideo"
        :products-loading="productsLoading"
        @close-add-to-cart="showProductsModal = false"
        @submit-add-to-cart-products="tagProductsToVideos"
      />
      <AddVideosToWidgetModal
        v-if="showAddVideosToWidgetModal"
        :possible-widgets="possibleWidgets"
        :selected-videos="selectedVideos"
        @close="showAddVideosToWidgetModal = false"
      />
      <SeeProductsModal
        v-if="showSeeProductsModal"
        :selected-video="selectedVideoToSeeProducts"
        :products="products"
        :campaigns="campaigns"
        @open-select-widget-modal="openSelectWidgetModal"
        @remove-product="removeProduct"
        @close="showSeeProductsModal = false"
      />
      <spinner-loader
        v-if="isLoading"
        class="spinner-loader"
        :loading-text="$t('shared.loader.uploadingVideo')"
        :loading-text-additional="$t('shared.loader.uploadingVideo2')"
        :start-value="getUploadVideos.videosUploaded"
        :end-value="numberOfVideosToUpload"
      ></spinner-loader>
    </div>
    <EmbedCodeModal
      v-if="showEmbedCodeModal"
      :campaign="selectedCampaignObject"
      @close="closeEmbedCodeModal"
    />
    <SelectWidgetModal
      v-if="showSelectWidgetModal"
      :possible-widgets="possibleWidgets"
      :selected-product-id="selectedProductId"
      :is-add-widget="isAddWidget"
      @close="closeAddVideosToWidgetModal"
      @widget-selected="addWidgetToProduct"
    />
    <AddTitleToVideoModal
      v-if="showTitleModal"
      :selected-videos="selectedVideos"
      :video-library="getVideoLibrary"
      @close="showTitleModal = false"
      @confirm="addTitleToVideos"
    />
  </sliding-modal>
</template>
<script>
import SlidingModal from "@/components/shared/SlidingModal.vue";
import VideoLibrary from "@/components/create-campaign-steps/VideoLibrary.vue";
import VidjetPreview from "../shared/Vidjet-preview.vue";
import TrashIcon from "@/assets/svg/trash-icon.svg?inline";
import DownloadIcon from "@/assets/svg/download-icon.svg?inline";
import TagProduct from "@/assets/svg/tag-products.svg?inline";
import PlusIcon from "@/assets/svg/plus-icon.svg?inline";
import TitleIcon from "@/assets/svg/title-icon.svg?inline";
import GooglePicker from "../shared/GooglePicker.vue";
import SmallModal from "../shared/SmallModal";
import PlanRegistration from "@/components/upgrade-plan/PlanRegistration.vue";
import YoutubeImporter from "@/components/create-campaign-steps/YoutubeImporter.vue";
import DeleteVideosModal from "@/components/DeleteVideosModal.vue";
import SpinnerLoader from "../shared/SpinnerLoader.vue";
import PlayErrorModal from "@/components/create-campaign-steps/PlayErrorModal.vue";
import AddToCartModal from "@/components/create-campaign-steps/step3/AddToCartModal.vue";
import AddVideosToWidgetModal from "@/components/shared/AddVideosToWidgetModal.vue";
import SeeProductsModal from "@/components/shared/SeeProductsModal.vue";
import EmbedCodeModal from "@/components/campaign-manager/EmbedCodeModal.vue";
import SelectWidgetModal from "@/components/SelectWidgetModal.vue";
import AddTitleToVideoModal from "@/components/shared/AddTitleToVideoModal.vue";
import { mapGetters, mapState } from "vuex";
import { generateVideoCover } from "../../utils/generate-video-cover";
import api from "../../api";
import axios from "axios";

export default {
  components: {
    SlidingModal,
    VideoLibrary,
    VidjetPreview,
    TrashIcon,
    DownloadIcon,
    GooglePicker,
    SmallModal,
    YoutubeImporter,
    DeleteVideosModal,
    SpinnerLoader,
    PlanRegistration,
    PlayErrorModal,
    TagProduct,
    PlusIcon,
    AddToCartModal,
    AddVideosToWidgetModal,
    SeeProductsModal,
    EmbedCodeModal,
    SelectWidgetModal,
    TitleIcon,
    AddTitleToVideoModal,
  },
  props: {
    preselectedQuery: {
      type: String,
      default: "",
    },
    videoLibraryCreation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedVideo: {
        height: 400,
        width: 200,
        duration: 15,
        isPremade: false,
        url: "https://media.vidjet.io/New+default+preview.mp4",
        thumbnail: require("../../assets/new-preview-thumbnail.png"),
      },
      areVideosSelected: false,
      showPlanRegistrationModal: false,
      videoMetadataLoading: false,
      files: [],
      selectedVideos: [],
      changeBackground: false,
      showImporterModal: false,
      selectedImporter: "",
      openDeleteVideosModal: false,
      campaigns: [],
      isLoading: false,
      appsumoMaxFileSize: 1000,
      isFileTooBig: false,
      showErrorModal: false,
      proposedPlan: {},
      showProductsModal: false,
      showAddVideosToWidgetModal: false,
      showSeeProductsModal: false,
      selectedVideoToSeeProducts: null,
      possibleWidgets: null,
      selectedCurrentFeedEdit: null,
      videosOnBulkObject: null,
      defaultCtaObject: {
        textColor: "#000000",
        buttonColor: "#E5E5E5",
        ctaType: 3,
        ctaAction: "buyNow",
        bottomMarginPercentage: 10,
        buttonShape: "semi",
        productIds: [],
      },
      showEmbedCodeModal: false,
      selectedCampaignObject: null,
      selectedProductId: null,
      isAddWidget: false,
      showSelectWidgetModal: false,
      productsLoading: false,
      showTitleModal: false,
    };
  },
  computed: {
    ...mapState(["site"]),
    ...mapState({
      products: (state) => state.product.products,
    }),
    ...mapGetters({
      videosInCampaignsLimit: "plan/videosInCampaignsLimit",
      numberOfVideosInLibrary: "video/numberOfVideosInLibrary",
      currentPlan: "plan/currentPlan",
      getVideoLibrary: "video/getVideoLibrary",
      siteId: "account/getSiteId",
      getUploadVideos: "video/getUploadVideos",
    }),
    numberOfSelectedVideosInCampaigns() {
      const selectedVideoIds = new Set(this.selectedVideos);
      const videosInCampaigns = new Set();

      this.campaigns.forEach((campaign) => {
        if (campaign.videos) {
          campaign.videos.forEach((video) => {
            videosInCampaigns.add(video.videoId);
          });
        }

        if (campaign.bulk) {
          campaign.bulk.forEach((bulk) => {
            if (bulk.videos) {
              bulk.videos.forEach((subVideo) => {
                videosInCampaigns.add(subVideo.videoId);
              });
            }
          });
        }
      });

      let numberOfSelectedVideosInCampaigns = 0;
      selectedVideoIds.forEach((id) => {
        if (videosInCampaigns.has(id)) {
          numberOfSelectedVideosInCampaigns++;
        }
      });

      return numberOfSelectedVideosInCampaigns;
    },
    campaignNamesWithSelectedVideos() {
      const selectedVideoIds = new Set(this.selectedVideos);
      const campaignsInfo = [];
      this.campaigns.forEach((campaign) => {
        if (campaign.videos) {
          campaign.videos.forEach((video) => {
            if (selectedVideoIds.has(video.videoId)) {
              campaignsInfo.push({
                campaignName: campaign.name,
                feedName: campaign.feedName || null,
              });
            }
          });
        }
        if (campaign.bulk) {
          campaign.bulk.forEach((bulk) => {
            if (bulk.videos) {
              bulk.videos.forEach((subVideo) => {
                if (selectedVideoIds.has(subVideo.videoId)) {
                  campaignsInfo.push({
                    campaignName: campaign.name,
                    feedName: bulk.feedName || null,
                  });
                }
              });
            }
          });
        }
      });

      // Remove duplicate objects
      const uniqueCampaignsInfo = campaignsInfo.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (obj) =>
              obj.campaignName === value.campaignName &&
              obj.feedName === value.feedName
          )
      );

      return uniqueCampaignsInfo;
    },
    numberOfVideosToUpload() {
      return this.files.filter((file) => !file._id).length;
    },
    uploadBlockBackground() {
      const opacity = this.changeBackground ? "0.1" : "0.3";
      return `background: rgba(226, 124, 252, ${opacity})`;
    },
    taggedProductsOnVideo() {
      if (this.selectedVideos.length === 1) {
        const selectedVideoId = this.selectedVideos[0];

        const video = this.getVideoLibrary.find(
          (v) => v._id === selectedVideoId
        );
        if (
          video &&
          Array.isArray(video.productIds) &&
          video.productIds.length > 0
        ) {
          return video.productIds
            .map((productId) =>
              this.products.find((product) => product._id === productId)
            )
            .filter(Boolean);
        } else {
          return false;
        }
      }

      return false;
    },
  },
  watch: {
    "selectedVideos.length"(newLength) {
      if (newLength === 0) {
        this.resetSelectedVideo();
      }
    },
  },
  async created() {
    this.campaigns = [
      ...(await this.$store.dispatch("campaign/getCampaigns", {
        siteId: this.siteId,
      })),
    ];
    await this.$store.dispatch("product/getProducts", {
      siteId: this.siteId,
      isProductListView: true,
    });
    if (this.videoLibraryCreation) {
      this.selectedCampaignObject = this.campaigns.find(
        (campaign) => campaign._id === this.$route.query.campaignId
      );

      this.showEmbedCodeModal = true;
    }
  },
  methods: {
    close() {
      this.$store.commit("video/resetVideos");
      this.$emit("close-video-library");
    },
    selectVideoInPreview(video) {
      this.selectedVideo = video;
    },
    handleSelectedVideos(hasSelectedVideos) {
      this.areVideosSelected = hasSelectedVideos ? true : false;
    },
    handleSelectedVideosUpdate(updatedSelectedVideos) {
      this.selectedVideos = updatedSelectedVideos;
    },
    deleteSelectedVideos() {
      for (const videoId of this.selectedVideos) {
        this.$store.commit("video/deleteVideoFromLibrary", videoId);
      }

      this.selectedVideos = [];
      this.areVideosSelected = false;
      this.resetSelectedVideo();
      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        text: this.$t("create.step2.videosDeleted"),
        type: "success",
      });
      this.handleCloseDeleteVideosModal();
    },
    async onFileChanged(payload) {
      let videosToUpload;
      if (payload.type === "drop") {
        videosToUpload = Array.from(payload.dataTransfer.files);
      } else if (payload.type === "quick-import") {
        videosToUpload = [payload.file];
      } else {
        videosToUpload = Array.from(payload.target.files);
      }

      if (
        videosToUpload?.length &&
        this.numberOfVideosInLibrary + videosToUpload.length >
          this.videosInCampaignsLimit
      ) {
        return this.showPlanRegistration();
      }
      this.isLoading = true;
      // Process each video file
      for (const videoToUpload of videosToUpload) {
        const loadedmetadataEvent = await this.processToVideoCheck(
          videoToUpload
        );
        if (!loadedmetadataEvent) continue;
        // Generate video object
        const videoMetadata = this.addMetadata(loadedmetadataEvent);
        let thumbnail = await generateVideoCover(
          URL.createObjectURL(videoToUpload),
          4
        );
        thumbnail = URL.createObjectURL(thumbnail);

        let fileName = videoToUpload.name;
        let count = 1;
        while (this.fileNameExists(fileName)) {
          fileName = this.getUniqueFileName(videoToUpload.name, count);
          count++;
        }
        const videoObject = {
          name: fileName,
          videoToUpload: videoToUpload,
          url: URL.createObjectURL(videoToUpload),
          thumbnail,
        };
        this.files.push({
          ...videoMetadata,
          ...videoObject,
        });
        this.selectedVideo = this.files[this.files.length - 1];
        // this is commented for when youtube is back
        // this.isLoading = false;
      }

      // After pushing all video objects to files array, create videos in the store
      if (payload.source !== "youtube") {
        // this is comment for when youtube is back
        // this.isLoading = true;
        for (let i = 0; i < this.files.length; i++) {
          await this.createVideo(this.setVideoObject(this.files[i]), i);
        }
        await this.$store.dispatch("video/getVideoLibrary", {
          siteId: this.siteId,
          query: {
            $or: [{ isPremade: { $exists: false } }, { isPremade: false }],
            $and: [
              {
                fileName: { $exists: true },
                height: { $exists: true },
                width: { $exists: true },
                videoDuration: { $exists: true },
                url: { $not: { $regex: "blob:" } },
              },
            ],
            getAnalytics: true,
          },
        });
        this.isLoading = false;
        this.files = [];
      }
    },
    async showPlanRegistration() {
      if (this.currentPlan === "pro") {
        window.Intercom &&
          window.Intercom("trackEvent", "plan_enterprise_clicked");
        this.notifyTooManyVideos();
        return;
      }
      this.proposedPlan = await this.$store.dispatch("plan/getProposedPlan", {
        siteId: this.siteId,
      });
      this.showPlanRegistrationModal = true;
      this.notifyTooManyVideos();
    },
    notifyTooManyVideos() {
      this.$notify({
        text: this.$t("shared.toastMessage.tooManyVideos"),
        type: "error",
      });
    },
    async processToVideoCheck(video) {
      if (!this.checkFileType(video.name)) {
        return;
      }
      this.checkFileSize(video.size);
      if (!this.isFileTooBig) {
        this.isFileTooBig = false;
        const res = await this.isBrowserCompatible(video);
        !res && this.togglePlayErrorModal();
        return res;
      }
    },
    checkFileType(fileName) {
      if (!this.validateFileType(fileName)) {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("create.step2.alerts.supportedFormats"),
          type: "error",
        });
        return false;
      }
      return true;
    },
    validateFileType(fileName) {
      const videoFormatsRegex = /(.mov|.mp4|.webm|.ogg)$/i;

      return videoFormatsRegex.test(fileName);
    },
    checkFileSize(size) {
      const sizeInMB = size / (1024 * 1024);
      if (
        this.currentPlan === "vidjet_tier3" &&
        sizeInMB > this.appsumoMaxFileSize
      ) {
        this.isFileTooBig = true;
        this.resetVideo();
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.appsumoYourFileExceeds"),
          type: "error",
        });
        if (sizeInMB > 300) {
          this.isFileTooBig = true;
          this.resetVideo();
          this.$notify({
            title: this.$t("shared.toastMessage.error"),
            text: this.$t("shared.toastMessage.yourFileExceeds"),
            type: "error",
          });
          return;
        }

        return;
      }
    },
    addMetadata(event) {
      const { videoHeight, videoWidth, duration } = event;
      this.videoMetadataLoading = false;
      return {
        height: videoHeight,
        width: videoWidth,
        videoDuration: duration,
      };
    },
    async isBrowserCompatible(file) {
      let video = document.createElement("video");
      video.setAttribute("src", window.URL.createObjectURL(file));
      var playPromise = video.play();
      video.muted = true;
      this.videoMetadataLoading = true;
      // wait for metadata to load to return a value
      const retVal = await new Promise((resolve) => {
        video.addEventListener("loadedmetadata", async (event) => {
          if (event.target.videoHeight === 0) {
            await playPromise.catch(() => {
              this.sendCodecsLog(file);
            });
            resolve(false);
          } else {
            video.pause();
            const target =
              event.target || event.currentTarget || event.originalTarget;
            resolve(target);
          }
        });
      });
      return retVal;
    },
    async sendCodecsLog(file) {
      let result = await this.retrieveCodecs(file);
      api.sendLog({
        logMsg: `Error uploading video for site ${this.siteId} with file ${file.name} and codecs: ${result}`,
      });
    },
    async retrieveCodecs(file) {
      let result = [];
      const MP4Box = require("mp4box");
      await new Promise((resolve, reject) => {
        const mp4boxFile = MP4Box.createFile();
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = function (e) {
          const arrayBuffer = e.target.result;
          arrayBuffer.fileStart = 0;
          mp4boxFile.appendBuffer(arrayBuffer);
        };
        mp4boxFile.onReady = function (info) {
          info.tracks.forEach((track) => {
            result.push(track.codec);
          });
          resolve(mp4boxFile);
        };
        mp4boxFile.onError = function (info) {
          reject(info);
        };
      });
      return result;
    },
    async createVideo(file, index) {
      const video = await this.$store.dispatch("video/createVideo", {
        file: {
          siteId: this.siteId,
          ...file,
        },
        index,
      });

      return video;
    },
    setVideoObject(file) {
      const videoData = {
        useCase: this.$route.query.goal,
        videoDuration: Number(file.videoDuration.toFixed(1)),
        height: file.height,
        width: file.width,
        isPremade: file.isPremade,
        _id: file._id,
      };

      videoData.tempFile = file.videoToUpload;
      videoData.fileName = file.name;
      videoData.url = file.url;
      videoData.thumbnail = file.thumbnail;

      return videoData;
    },
    async createVideosFromYoutube() {
      this.isLoading = true;
      for (let i = 0; i < this.files.length; i++) {
        await this.createVideo(this.setVideoObject(this.files[i]), i);
      }
      this.toggleImporterModal();
      await this.$store.dispatch("video/getVideoLibrary", {
        siteId: this.siteId,
        query: {
          $or: [{ isPremade: { $exists: false } }, { isPremade: false }],
          $and: [
            {
              fileName: { $exists: true },
              height: { $exists: true },
              width: { $exists: true },
              videoDuration: { $exists: true },
              url: { $not: { $regex: "blob:" } },
            },
          ],
          getAnalytics: true,
        },
      });
      this.isLoading = false;
      this.files = [];
    },
    async toggleImporterModal(importer) {
      if (
        this.files.length &&
        this.numberOfVideosInLibrary + this.files.length >
          this.videosInCampaignsLimit
      ) {
        return this.showPlanRegistration();
      }
      this.showImporterModal = !this.showImporterModal;
      this.showImporterModal && (this.importUrl = "");
      this.selectedImporter = importer;
    },
    removeVideos() {
      this.files = [];
      this.resetSelectedVideo();
      this.toggleImporterModal();
    },
    handleOpenDeleteVideosModal() {
      if (this.selectedVideos.length > 0) {
        this.openDeleteVideosModal = true;
      }
    },
    handleCloseDeleteVideosModal() {
      this.openDeleteVideosModal = false;
    },
    getSelectedVideosFromLibrary() {
      return this.getVideoLibrary.filter((video) =>
        this.selectedVideos.includes(video._id)
      );
    },
    async handleDownloadSelectedVideos() {
      const selectedVideos = this.getSelectedVideosFromLibrary();

      selectedVideos.forEach((video) => {
        this.downloadItem({ url: video.url, label: video.fileName });
      });
    },
    downloadItem({ url, label }) {
      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data]);
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = label;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    },
    resetSelectedVideo() {
      this.selectedVideo = {
        height: 400,
        width: 200,
        duration: 15,
        isPremade: false,
        url: "https://media.vidjet.io/New+default+preview.mp4",
        thumbnail: require("../../assets/new-preview-thumbnail.png"),
      };
    },
    togglePlayErrorModal() {
      this.showErrorModal = !this.showErrorModal;
    },
    resetVideo() {
      this.files = [];
      this.selectedVideo = null;
    },
    fileNameExists(fileName) {
      return this.getVideoLibrary.some((video) => video.fileName === fileName);
    },

    getUniqueFileName(fileName, count) {
      const extensionIndex = fileName.lastIndexOf(".");
      if (extensionIndex !== -1) {
        return `${fileName.slice(0, extensionIndex)} (${count})${fileName.slice(
          extensionIndex
        )}`;
      } else {
        return `${fileName} (${count})`;
      }
    },
    openProductModal() {
      if (this.selectedVideos.length > 0) {
        this.showProductsModal = true;
      }
    },
    openWidgetModal() {
      const noProductId = this.selectedVideos.some((videoId) => {
        const video = this.getVideoLibrary.find((item) => item._id === videoId);
        return video && !video.productIds;
      });
      if (!this.selectedVideos || this.selectedVideos.length === 0) return;
      else if (noProductId) {
        this.$notify({
          title: "Error",
          text: "One or more videos doesn't have products tagged. First you need to tag products to these videos",
          type: "error",
        });
      } else {
        this.possibleWidgets = this.campaigns.filter(
          (campaign) => campaign.bulk
        );
        this.showAddVideosToWidgetModal = true;
      }
    },
    async tagProductsToVideos(selectedProducts) {
      this.productsLoading = true;
      const selectedVideoObjects = this.getVideoLibrary.filter((video) =>
        this.selectedVideos.includes(video._id)
      );

      for (const video of selectedVideoObjects) {
        const currentProductIds = video.productIds || [];

        const newProductIds = selectedProducts.map((product) => product._id);

        const updatedProductIds = [
          ...new Set([...currentProductIds, ...newProductIds]),
        ];

        await this.$store.dispatch("video/updateVideoProductIds", {
          videoId: video._id,
          productIds: updatedProductIds,
        });
      }
      this.productsLoading = false;
      this.showProductsModal = false;
    },
    openProductListModal(video) {
      this.selectedVideoToSeeProducts = video;
      this.showSeeProductsModal = true;
    },
    findMatchingBulk(campaign, productId) {
      for (let bulkItem of campaign.bulk) {
        if (
          bulkItem.segmentation &&
          bulkItem.segmentation.productId &&
          bulkItem.segmentation.productId.includes(productId)
        ) {
          return bulkItem;
        }
      }
      return null;
    },
    createNewFeed(productId) {
      let matchingProduct = this.products.find(
        (product) => product.productId === productId
      );
      const currentDate = new Date().toISOString();
      const newFeed = {
        feedName: matchingProduct.name,
        segmentation: {
          productId: [matchingProduct.productId],
        },
        videos: [],
        creationDate: currentDate,
      };
      this.$store.commit("campaign/createFeed", newFeed);
      return newFeed;
    },
    closeEmbedCodeModal() {
      this.showEmbedCodeModal = false;
      this.selectedCampaignObject = null;
    },
    openSelectWidgetModal(productId, possibleWidgets) {
      this.showSeeProductsModal = false;
      this.selectedProductId = productId;
      this.possibleWidgets = possibleWidgets;
      this.isAddWidget = true;
      this.showSelectWidgetModal = true;
    },
    closeAddVideosToWidgetModal() {
      this.showSelectWidgetModal = false;
      this.possibleWidgets = null;
      this.selectedProductId = null;
      this.isAddWidget = false;
    },
    async addWidgetToProduct(campaignId, productId) {
      const campaign = await this.fetchCampaign(campaignId, this.siteId);
      const newFeed = this.createNewFeed(productId);
      this.$store.commit("campaign/setCurrentFeedEdit", newFeed);
      this.selectedCurrentFeedEdit = newFeed;
      const updatedBulk = [...campaign.bulk, newFeed];
      await this.$store.dispatch("campaign/updateCampaign", {
        campaignId: campaign._id,
        dataToUpdate: { bulk: updatedBulk },
      });
      this.closeAddVideosToWidgetModal();
    },
    async fetchCampaign(campaignId, siteId) {
      const { campaign } = await this.$store.dispatch("campaign/getSingle", {
        campaignId: campaignId,
        siteId: siteId,
      });
      return campaign;
    },
    async updateProductVideoLastImportDate(productId) {
      const product = this.products.find((p) => p.productId === productId);
      if (product) {
        const now = new Date().toISOString();
        product.videoLastImportDate = now;

        try {
          await this.$store.dispatch("product/updateProduct", {
            productId: product._id,
            productData: { videoLastImportDate: product.videoLastImportDate },
          });
        } catch (error) {
          console.error("Error updating product:", error);
        }
      }
    },
    async removeProduct(product) {
      const video = this.selectedVideoToSeeProducts;

      if (video) {
        const currentProductIds = video.productIds || [];

        const updatedProductIds = currentProductIds.filter(
          (productId) => productId !== product._id
        );

        await this.$store.dispatch("video/updateVideoProductIds", {
          videoId: video._id,
          productIds: updatedProductIds,
        });
      }
    },
    openTitleModal() {
      if (this.areVideosSelected) {
        this.showTitleModal = true;
      }
    },
    async addTitleToVideos(title) {
      const selectedVideoObjects = this.getVideoLibrary.filter((video) =>
        this.selectedVideos.includes(video._id)
      );
      for (const video of selectedVideoObjects) {
        await this.$store.dispatch("video/updateVideoTitle", {
          videoId: video._id,
          videoTitle: title,
        });
      }
      this.$notify({
        title: this.$t("create.step2.addTitleModal.toastMessage"),
        type: "success",
      });
      this.showTitleModal = false;
    },
  },
};
</script>

<style lang="scss">
.video-library-sliding-modal .sliding-modal {
  width: 90%;
  background: linear-gradient(
    to right,
    white 456px,
    $light-grey 456px 457px,
    $light-pink 457px
  );
  overflow: hidden;
}

@media (max-width: 1250px) {
  .video-library-sliding-modal .sliding-modal {
    background: linear-gradient(
      to right,
      white 440px,
      $light-grey 440px 441px,
      $light-pink 441px
    );
  }
}
</style>

<style lang="scss" scoped>
.main-container {
  display: flex;
  padding-left: 24px;
  position: relative;
}
.preview-wrapper {
  height: 100vh;
  width: 75%;
  display: grid;
  grid-template-rows: 1fr minmax(0, auto);
  padding: 0;
}

.video-menu {
  z-index: 102;
  height: 70px;
  max-width: 850px;
  position: fixed;
  left: 50%;
  transform: translateX(-40%);
  bottom: 16px;
  background: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 0 32px;
  border: 1px solid $light-grey;
  box-shadow: 0px 2px 8px 0px #00000026;
}
.icons {
  display: flex;
  align-items: center;
  gap: 32px;
  cursor: default;
}

.icon {
  outline: none;
}

.delete-video-icon {
  scale: 120%;
  path {
    fill: $light-grey;
  }
}
.title-icon {
  path {
    fill: $light-grey;
  }
}

.tag-product-icon {
  path {
    fill: $light-grey;
  }
}

.add-widget-icon {
  path {
    fill: $light-grey;
  }
}

.selected-icon {
  cursor: pointer;
  path {
    fill: $dark-purple;
  }
}

.separation-line {
  height: 27px;
  width: 1px;
  background: $light-grey;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
}

.upload-video-area {
  cursor: pointer;
  border-radius: $card-border-radius;
  border: 2px dashed $dark-purple;
  height: 100%;
  padding: 12px;
  @include flex-centered;
  background: $light-pink;
  white-space: nowrap;
}
.flex-centered {
  @include flex-centered;
}

.upload-button {
  font-family: "Montserrat", sans-serif;
  background: $dark-purple;
  color: white;
  @include font-small;
  padding: 2px 12px;
  border-radius: 5px;
  margin-right: 6px;
}

.upload-drop-text-floating {
  font-size: 14px;
}

.hidden {
  display: none;
}
.quick-import-button {
  @include base-font;
  @include font-small;
  border-radius: 4px;
  background-color: white;
  padding: 0 5px;
  height: 30px;
  display: flex;
  align-content: center;
  align-items: center;
  gap: 8px;
  margin: 0px auto;
  box-sizing: content-box;
  white-space: nowrap;
  &:hover {
    background: $light-pink;
  }
  img {
    vertical-align: middle;
  }
  .youtube-logo {
    height: 30px;
  }
}

.spinner-loader {
  z-index: 1000;
  transform: translate(-50%, -50%);
}

.add-to-cart-modal {
  z-index: 500;
}
</style>
