import api from "../api";
import Vue from "vue";

import {
  startAsyncUploadToS3,
  blobUrlToFile,
  uploadThumbnailForVideo,
} from "../utils/uploadToAmplify";

const state = {
  videos: [],
  videoLibrary: [],
  uploadVideos: {
    videosToUpload: 0,
    videosUploaded: 0,
  },
};
const getters = {
  getVideos: (state) => {
    return state.videos;
  },

  getVideoLibrary(state) {
    return state.videoLibrary;
  },
  numberOfVideosInLibrary(state) {
    return state.videoLibrary.length;
  },
  getUploadVideos(state) {
    return state.uploadVideos;
  },
};

const mutations = {
  addVideo(state, { video, index }) {
    state.videos.splice(index, 0, video);
  },
  updateVideo(state, { video }) {
    const indexOfVideo = state.videos.findIndex(
      (item) => item._id === video._id
    );

    Vue.set(state.videos, indexOfVideo, video);
  },
  updateVideoThumbnail(state, { video }) {
    if (!video) return;
    const indexOfVideo = state.videos.findIndex(
      (item) => item._id === video._id
    );
    let videoCopy = state.videos[indexOfVideo];
    videoCopy.thumbnail = video.thumbnail;
    videoCopy.thumbnailS3Path = video.thumbnailS3Path;
    Vue.set(state.videos, indexOfVideo, videoCopy);
  },
  deleteVideo(state, index) {
    if (index <= state.videos.length) {
      state.videos.splice(index, 1);
    }
  },
  async deleteVideoFromLibrary(state, videoId) {
    const index = state.videoLibrary.findIndex(
      (video) => video._id === videoId
    );
    if (index !== -1) {
      state.videoLibrary.splice(index, 1);

      try {
        await api.deleteVideo(videoId);
      } catch (err) {
        console.error("Error deleting video:", err);
      }
    }
  },
  resetVideos(state) {
    state.videos = [];
  },
  setVideos(state, videos) {
    state.videos = videos;
  },

  setVideoLibrary(state, videos) {
    state.videoLibrary = videos;
  },
  VIDEO_UPLOADED(state) {
    state.uploadVideos.videosUploaded += 1;
  },
  VIDEO_TO_UPLOAD(state) {
    state.uploadVideos.videosToUpload += 1;
  },
  RESET_UPLOAD_VIDEOS(state) {
    state.uploadVideos.videosToUpload = 0;
    state.uploadVideos.videosUploaded = 0;
  },
  ADD_VIDEO_TO_LIBRARY(state, video) {
    state.videoLibrary.push(video);
  },
  updateVideoLibraryFileName(state, { videoId, fileName }) {
    const video = state.videoLibrary.find((video) => video._id === videoId);
    if (video) {
      video.fileName = fileName;
    }
  },
  updateVideoLibraryProductIds(state, { videoId, productIds }) {
    const video = state.videoLibrary.find((video) => video._id === videoId);
    if (video) {
      Vue.set(video, "productIds", productIds);
    }
  },
  updateVideoLibraryVideoTitle(state, { videoId, videoTitle }) {
    const video = state.videoLibrary.find((video) => video._id === videoId);
    if (video) {
      Vue.set(video, "videoTitle", videoTitle);
    }
  },
};

const actions = {
  async createVideo({ commit }, { file, index }) {
    // create the campaign first so we retreive the proper document ID. To avoid duplicate key in Mongo
    let video;
    const tempFile = file.tempFile;
    if (file._id) {
      video = file;
      video.alreadyCreated = true;
    } else {
      delete file.tempFile;
      video = await api.createVideo(file);
    }
    let blobThumbnail;
    if (file.thumbnail && file.thumbnail.includes("blob:http")) {
      // keep the blob url to display the thumbnail in the UI until the thumbnail is uploaded to S3
      blobThumbnail = file.thumbnail;
      const thumbnailFile = await blobUrlToFile(file.thumbnail, video.fileName);
      video.tempThumbnailFile = thumbnailFile;
      let result = await uploadThumbnailForVideo(video);
      api.updateVideo(video._id, {
        thumbnail: result.thumbnail.replace(
          "https://image-compression-pipeline-destination.s3.eu-west-1.amazonaws.com",
          process.env.VUE_APP_IMAGE_CDN_URL
        ),
        thumbnailS3Path: result.thumbnailS3Path,
      });
      delete video.tempThumbnailFile;
    }
    file.thumbnail && (video.thumbnail = file.thumbnail);
    blobThumbnail && (video.thumbnail = blobThumbnail);
    await commit("addVideo", { video, index });
    if (tempFile) {
      commit("VIDEO_TO_UPLOAD");
      const res = await startAsyncUploadToS3(video, tempFile);
      if (res.status === "success") {
        commit("VIDEO_UPLOADED");
      } else {
        // commit("deleteVideo", index);
      }
    } else {
      commit("VIDEO_TO_UPLOAD");
      commit("VIDEO_UPLOADED");
    }

    return video;
  },
  async getVideo({ commit }, videoId) {
    try {
      const video = await api.getVideo(videoId);
      commit("addVideo", video);
    } catch (err) {
      throw err;
    }
  },
  async updateVideoThumbnail(
    { commit },
    { videoId, dataToUpdate, blobThumbnail = null }
  ) {
    try {
      const videoUpdated = await api.updateVideo(videoId, dataToUpdate);
      // on step 2 we want the blob because compression can take time and we want to show the user the thumbnail as soon as possible
      blobThumbnail && (videoUpdated.thumbnail = blobThumbnail);
      await commit("updateVideoThumbnail", { video: videoUpdated });
      return {
        status: "success",
        res: "video.updated",
        id: videoUpdated._id,
      };
    } catch (err) {
      throw new Error(err);
    }
  },
  async getVideoLibrary({ commit }, data) {
    const videos = await api.getVideos(data);
    commit("setVideoLibrary", videos);
  },
  async deleteVideoFromLibrary({ commit }, videoId) {
    commit("deleteVideoFromLibrary", videoId);
  },
  async addVideoToLibrary({ commit }, video) {
    commit("ADD_VIDEO_TO_LIBRARY", video);
  },
  async updateVideoLibraryFileName({ commit }, { videoId, fileName }) {
    try {
      await api.updateVideo(videoId, { fileName });
      commit("updateVideoLibraryFileName", { videoId, fileName });

      return {
        status: "success",
        message: "File name updated successfully",
      };
    } catch (err) {
      throw new Error(err);
    }
  },
  async updateVideo({ commit }, video) {
    try {
      commit("updateVideo", { video });
    } catch (err) {
      throw new Error(err);
    }
  },
  async updateVideoProductIds({ commit }, { videoId, productIds }) {
    try {
      await api.updateVideo(videoId, { productIds });
      commit("updateVideoLibraryProductIds", { videoId, productIds });
      return {
        status: "success",
        message: "Product IDs updated successfully",
      };
    } catch (err) {
      throw new Error(err);
    }
  },
  async updateVideoTitle({ commit }, { videoId, videoTitle }) {
    try {
      await api.updateVideo(videoId, { videoTitle });

      const video = { _id: videoId, videoTitle };
      commit("updateVideoLibraryVideoTitle", { videoId, videoTitle });

      return {
        status: "success",
        message: "Video title updated successfully",
      };
    } catch (err) {
      throw new Error(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
