<template>
  <div class="step4-wrapper">
    <section
      class="segmentationStep"
      :class="{ 'widget-creator-segmentation-step': isWidgetCreator }"
    >
      <div class="settings">
        <div class="blocks-wrapper">
          <div class="segmentation-block">
            <h3 class="segmentation-block-title">
              {{ $t("create.step4.whichPages.heading") }}
            </h3>
            <PagesSegmentation
              :segmentation="segmentation"
              @updateSegmentation="handlePageSegmentation"
            />
          </div>
          <div class="segmentation-block">
            <h3 class="segmentation-block-title">
              {{ $t("create.step4.trigger.heading") }}
            </h3>
            <trigger-segmentation :value="trigger" />
          </div>
          <div class="segmentation-block">
            <h3 class="segmentation-block-title">
              {{ $t("create.step4.restrictions.heading") }}
            </h3>
            <div class="restrict-views">
              {{ $t("create.step4.restrictions.after") }}
              <input
                id="maxView"
                v-model.number="viewRestriction.maxView"
                class="number-input"
                type="number"
                min="0"
                @change="validateInput"
              />
              {{ $t("create.step4.restrictions.views") }}
              <input
                id="notShownTime"
                v-model.number="viewRestriction.notShownTime"
                class="number-input"
                type="number"
                min="0"
                @change="validateInput"
              />
              {{ $t("create.step4.restrictions.hoursUser") }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="edit-footer-wrapper">
      <edit-footer
        v-if="isEditMode"
        class="edit-footer"
        :disabled-next="isLoading"
        :disabled-prev="isLoading"
        :next-text="$t('shared.buttons.submit')"
        @prev="editGoBack"
        @next="editSave"
      />
    </div>
    <spinner-loader
      v-if="showUploadProgress"
      :loading-text="$t('shared.loader.uploadingVideo')"
      :loading-text-additional="$t('shared.loader.uploadingVideo2')"
      :start-value="getUploadVideos.videosUploaded"
      :end-value="getUploadVideos.videosToUpload"
      @videos-uploaded="saveCampaignToDb()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TriggerSegmentation from "@/components/shared/Trigger-segmentation.vue";
import SettingsFooter from "@/components/create-campaign-steps/SettingsFooter";
import SpinnerLoader from "@/components/shared/SpinnerLoader";
import EditFooter from "@/components/create-campaign-steps/EditFooter";
import PagesSegmentation from "@/components/create-campaign-steps/PagesSegmentation.vue";

import campaignCreationMixin from "./campaignCreationMixin";

export default {
  name: "CreateCampaignStep4",
  components: {
    TriggerSegmentation,
    SettingsFooter,
    SpinnerLoader,
    EditFooter,
    PagesSegmentation,
  },

  mixins: [campaignCreationMixin],

  beforeRouteEnter(t, f, next) {
    next(async (vm) => {
      vm.init();
    });
  },

  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    isWidgetCreator: {
      type: Boolean,
      default: false,
    },
    isHomePage: {
      type: Boolean,
      default: false,
    },
    homePageWebSite: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      trigger: null,
      viewRestriction: {
        maxView: 3,
        notShownTime: 24,
      },
      defaultSegmentation: {
        trigger: {
          type: 0,
        },
        viewRestriction: {
          maxView: 10,
          notShownTime: 24,
        },
      },
      segmentation: {},
      isLoading: false,
      showSignupModal: false,
      campaign: {},
    };
  },

  computed: {
    ...mapGetters({
      getCampaignCreation: "campaign/campaignCreation",
      getIsLoggedIn: "account/getIsLoggedIn",
      siteId: "account/getSiteId",
    }),
  },

  async created() {
    if (this.isEditMode) {
      // await this.$store.dispatch("campaign/getSingle", {
      //   campaignId: this.$route.params.campaignId,
      //   siteId: this.siteId,
      // });
      this.campaign = this.$store.state.campaign.singleCampaign;
    } else {
      this.campaign = this.getCampaignCreation();
      this.campaign.segmentation = { ...this.defaultSegmentation };
    }

    this.segmentation = this.campaign.segmentation;

    if (!this.isEditMode && !this.campaign) {
      return;
    }

    if (this.isEditMode && !this.campaignSelected && !this.campaign) {
      return this.$router.push({ name: "campaignManager" });
    }

    this.trigger =
      this.campaign.segmentation && this.campaign.segmentation.trigger
        ? Object.assign({}, this.campaign.segmentation.trigger)
        : this.trigger;

    this.viewRestriction =
      this.campaign.segmentation && this.campaign.segmentation.viewRestriction
        ? Object.assign({}, this.campaign.segmentation.viewRestriction)
        : this.viewRestriction;

    if (this.isHomePage && this.homePageWebSite) {
      if (!this.segmentation.url) this.segmentation.url = [];
      this.segmentation.url.push({
        comparison: "eq",
        string: this.homePageWebSite,
      });
      this.$emit("update-page-segmentation", this.formatSegmentationData());
    }
  },
  methods: {
    toggleSignupModal() {
      this.showSignupModal = !this.showSignupModal;
    },

    nextStepActions() {
      // if some triggers are equalTo check url has the right format
      const canGoNext = this.areInputsValid();
      if (!canGoNext) {
        return;
      }
      if (this.getIsLoggedIn && this.siteId) {
        this.isLoading = true;
        try {
          this.$store.commit("campaign/campaignCreationSetSegmentation", {
            segmentation: this.formatSegmentationData(),
          });
        } catch (err) {
          this.isLoading = false;
          this.$notify({
            title: this.$t("shared.toastMessage.error"),
            text: err,
            type: "error",
          });
        }
        if (
          this.getUploadVideos.videosToUpload >
          this.getUploadVideos.videosUploaded
        ) {
          this.showUploadProgress = true;
        } else {
          this.saveCampaignToDb();
        }
      } else {
        this.showSignupModal = true;
      }
    },

    handlePageSegmentation(payload) {
      this.segmentation = { ...this.segmentation, ...payload };
      const formattedSegmentation = this.formatSegmentationData();
      if (this.isWidgetCreator) {
        this.$emit("update-page-segmentation", formattedSegmentation);
      }
    },

    areInputsValid() {
      if (
        !this.segmentation.url ||
        !(
          this.segmentation.hasOwnProperty("areAllInputsFilled") &&
          this.segmentation.hasOwnProperty("equalTriggersHaveValidUrls")
        )
      ) {
        return true;
      }

      if (!this.segmentation.areAllInputsFilled) {
        this.notifyUserWrongUrl(this.$t("shared.toastMessage.segmentation"));
        return false;
      }

      if (!this.segmentation.equalTriggersHaveValidUrls) {
        this.notifyUserWrongUrl(this.$t("shared.toastMessage.wrongUrl"));
        return false;
      }
      return true;
    },

    checkInput(event) {
      event.target.value =
        parseInt(event.target.value) < 1 ? 1 : parseInt(event.target.value);
      if (event.target.id === "maxView") {
        this.viewRestriction.maxView = event.target.value;
      } else if (event.target.id === "notShownTime") {
        this.viewRestriction.notShownTime = event.target.value;
      }
    },
    validateInput() {
      const MAX_VALUE = 99999;
      if (this.viewRestriction.maxView > MAX_VALUE) {
        this.viewRestriction.maxView = 99999;
      } else if (this.viewRestriction.notShownTime > MAX_VALUE) {
        this.viewRestriction.notShownTime = 99999;
      } else if (this.viewRestriction.maxView < 1) {
        this.viewRestriction.maxView = 1;
      } else if (this.viewRestriction.notShownTime < 1) {
        this.viewRestriction.notShownTime = 1;
      }
    },
    formatSegmentationData() {
      const seg = {
        trigger: this.trigger,
        viewRestriction: this.viewRestriction,
      };

      if (this.segmentation?.url?.length) seg.url = this.segmentation.url;

      if (this.segmentation?.productId?.length)
        seg.productId = this.segmentation.productId;

      return seg;
    },
    goPreviousStep() {
      this.$store.commit("campaign/campaignCreationSetSegmentation", {
        segmentation: this.formatSegmentationData(),
      });

      // Send Step 4 previous event to GTM
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "campaign_create_step4_previous",
      });

      this.$router.push({ name: "createCampaignStep3" });
    },
    editGoBack() {
      this.$emit("edit-go-back");
    },

    async editSave() {
      const canGoNext = this.areInputsValid();
      if (!canGoNext) {
        return;
      }
      this.isLoading = true;
      await this.$store.dispatch("campaign/updateCampaign", {
        campaignId: this.campaign._id,
        dataToUpdate: {
          segmentation: this.formatSegmentationData(),
        },
      });
      const updatedSegmentation = {
        segmentation: this.formatSegmentationData(),
      };
      this.$store.commit(
        "campaign/updateSingleCampaignData",
        updatedSegmentation
      );

      this.isLoading = false;
      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        text: this.$t("shared.toastMessage.campaignUpdated"),
        type: "success",
      });
      this.$emit("edit-go-back");
    },
    notifyUserWrongUrl(text) {
      this.$notify({
        title: this.$t("shared.toastMessage.error"),
        text: text,
        type: "error",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$format-step-width: 60%;
$format-step-min-width: 420px;
$format-step-max-width: 575px;

.step4-wrapper {
  width: 100%;
}

.segmentationStep {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 24px 30px;

  .settings {
    min-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .step-title {
      @include font-big;
      margin-top: 30px;
      margin-bottom: 30px;
      text-align: center;
      .bold {
        font-weight: $w-bolder;
      }
    }

    .segmentation-block {
      @include frame;
      align-items: normal;
      padding: 16px;
      margin: 15px 0;
      .segmentation-block-title {
        @include font-normal;
        color: $dark-grey;
        margin-bottom: 14px;
      }
      .restrict-views {
        @include font-smaller;
        font-weight: 500;
      }
    }
  }
}

.widget-creator-segmentation-step {
  margin-bottom: 80px;
}

.segmentationStep::-webkit-scrollbar {
  width: 0;
}

.segmentationStep::-webkit-scrollbar-track {
  background-color: transparent;
}

.segmentationStep::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.settings-footer-wrapper {
  width: 20%;
  max-width: 20%;
  margin: 16px auto;
}
.edit-footer-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
}
.edit-footer {
  width: 40%;
  margin: 0 auto 10px;
}
.blocks-wrapper {
  margin-bottom: 70px;
}
</style>
