<template>
  <small-modal
    :title="$t('create.step2.addTitleModal.title')"
    class="add-title-modal"
    :fit-content="true"
    @close="close"
  >
    <div class="add-title-modal-container">
      <banner-modal
        class="banner-modal"
        :title="$t('deleteCampaign.information')"
        :text="bannerTitle"
      >
      </banner-modal>
      <div class="input-title-container">
        <label for="title-input" class="input-title-label">
          {{ $t("create.step2.addTitleModal.yourText") }}
        </label>
        <input
          id="title-input"
          ref="titleInput"
          class="title-input"
          type="text"
          :value="prefilledTitle"
          :placeholder="$t('create.step2.addTitleModal.placeholder')"
          maxlength="20"
        />
      </div>
      <div class="buttons">
        <input
          class="button-primary --grey"
          type="button"
          :value="$t('deleteCampaign.cancel')"
          @click.prevent="close"
        />
        <label class="button-primary button-flex">
          <input
            class="submit-button"
            type="submit"
            :value="$t('shared.buttons.confirm')"
            @click="confirmTitle"
          />
        </label>
      </div>
    </div>
  </small-modal>
</template>

<script>
import SmallModal from "../shared/SmallModal";
import BannerModal from "../shared/BannerModal.vue";

export default {
  components: {
    SmallModal,
    BannerModal,
  },
  props: {
    selectedVideos: {
      type: Array,
      default: () => [],
    },
    videoLibrary: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {};
  },
  computed: {
    bannerTitle() {
      return `${this.$t("create.step2.addTitleModal.banner1")}\n${this.$t(
        "create.step2.addTitleModal.banner2"
      )}`;
    },
    selectedVideoObjects() {
      return this.videoLibrary.filter((video) =>
        this.selectedVideos.includes(video._id)
      );
    },
    prefilledTitle() {
    const titles = this.selectedVideoObjects.map((video) => video.videoTitle);

    const allTitlesSame = titles.every((title) => title === titles[0]);
    return allTitlesSame ? titles[0] : "";
  },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    confirmTitle() {
      const titleValue = this.$refs.titleInput.value;
      this.$emit("confirm", titleValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-modal {
  margin-bottom: 24px;
}
.input-title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.title-input {
  @include input-style;
  height: 30px;
  width: 100%;
  padding: 0 12px;
  &::placeholder {
    color: rgba(196, 196, 196, 1);
  }
}

/*save and back button*/
.buttons {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 24px;
}

.button-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.submit-button {
  @include base-font;
  @include font-small;
  font-weight: 500;
  background: none;
  border: none;
}
</style>
