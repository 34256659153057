import SwiperVidjet from "./swiper";
import Vue from "vue";

// minimal store to share data between methods
let store = Vue.observable({
  isTouchable: false, // is the device touchable
  isSlidable: false, // is the video slidable
  isInPreview: false, // is the video in preview mode
  numberOfVideos: 1, // number of videos in the slider
  touchEvent: null, // touch event from SwiperVidjet
  index: 0, // current index of the video
  vh: 0, // screen inner height
  vw: 0, // screen inner width
  dragY: 0, // drag Y distance
  dragX: 0, //drag X distance
  vnode: {}, //to update current index in the parent component
  hasBeenInit: false, // to prevent multiple init,
  isHorizontalSwipe: false, // to prevent vertical swipe
  unbindSwipingListeners: () => { }, // function to unbind swiping listeners
  resizeObserver: null,
});

export const slide = {
  // bind method is called when the directive is attached to the element
  bind(el, binding, vnode) {
    const data = binding.value;
    data.isTouchable = data.isMobile && !data.isInPreview;
    // reset for carousel
    store.index = 0;
    store = { ...store, vnode: vnode, ...data };

    if (store.isSlidable || store.isInPreview)
      initSwiping({ slidingContainer: el });
    setScreenSize({ slidingContainer: el });
    store.resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === el) {
          setScreenSize({ slidingContainer: el });
          break;
        }
      }
    });

    store.resizeObserver.observe(el);

    window.addEventListener("resize", () => {
      setScreenSize({ slidingContainer: el });
    });
  },

  // update method is called when the component is updated
  update(el, binding, vnode) {
    // if the index has changed we need to move the slide
    // move slide to the current index
    const data = binding.value;
    data.isTouchable = data.isMobile && !data.isInPreview;
    if (data.index === 0 && data.numberOfVideos === 1 && store.isInPreview) {
      // in preview when going in customisation we need to reset the position
      store.index = 0;
      positionSlide({ slidingContainer: el });
    } else if (data.index !== store.index) {
      data.index - store.index > 0
        ? moveSlide({ swipeDirection: "up", slidingContainer: el })
        : moveSlide({ swipeDirection: "down", slidingContainer: el });
    }

    setScreenSize({ slidingContainer: el });

    // delete index because index in store is update after the transition
    delete data.index;
    store = { ...store, vnode: vnode, ...data };
    if ((store.isSlidable || store.isInPreview) && !store.hasBeenInit)
      initSwiping({ slidingContainer: el });
    positionSlide({ slidingContainer: el });
  },
  unbind() {
    store.unbindSwipingListeners();
    store.resizeObserver.disconnect();
  },
};

// init method is called when the directive is attached to the element
const initSwiping = ({ slidingContainer }) => {
  if (store.isHorizontalSwipe) return;
  store.unbindSwipingListeners = bindSwipingListeners({ slidingContainer });
  store.hasBeenInit = true;
};

// set screen height
const setScreenSize = ({ slidingContainer }) => {
  let vh = 0;
  let vw = 0;
  if (store.isInPreview) {
    vh = slidingContainer.offsetHeight / 100;
    vw = slidingContainer.offsetWidth / 100;
    vh !== 0 && document.documentElement.style.setProperty("--vh", `${vh}px`);
    vw !== 0 && document.documentElement.style.setProperty("--vw", `${vw}px`);
  } else {
    vh =
      (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) * 0.01;
    vw =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) * 0.01;
    vh !== 0 && document.documentElement.style.setProperty("--vh", `${vh}px`);
    vw !== 0 && document.documentElement.style.setProperty("--vw", `${vw}px`);
  }
  vh !== 0 && (store.vh = vh);
  vw !== 0 && (store.vw = vw);
  positionSlide({ slidingContainer });
};

const positionSlide = ({ slidingContainer }) => {
  const { index, isHorizontalSwipe } = store;
  slidingContainer.childNodes.forEach((child) => {
    child.style.transition = "transform 0.3s ease-out";
    const transformValue = isHorizontalSwipe
      ? `translateX(-${index * child.offsetWidth}px)`
      : `translateY(-${index * child.offsetHeight}px)`;
    child.style.transform = transformValue;
  })
};


const handleSwipe = ({ event, slidingContainer }) => {
  if (!store.touchEvent) {
    return;
  }
  store.touchEvent.setEndEvent(event);
  if (store.touchEvent.isSwipeDown() && store.index > 0) {
    moveSlide({ swipeDirection: "down", slidingContainer });
  } else if (store.touchEvent.isSwipeUp() && !isLastVideo()) {
    moveSlide({ swipeDirection: "up", slidingContainer });
  } else {
    positionSlide({ slidingContainer });
  }

  // Reset event for next touch
  store.touchEvent = null;
};

const moveSlide = ({ swipeDirection, slidingContainer }) => {
  const nextIndex =
    swipeDirection === "down" ? store.index - 1 : store.index + 1;
  store.dragY = 0;
  store.dragX = 0;
  store.index = nextIndex;
  positionSlide({
    slidingContainer
  });
  store.vnode.context.setCurrentIndex(nextIndex);
};

const initSwipeEvent = ({ event }) => {
  store.touchEvent = new SwiperVidjet(event, store.isTouchable);
};


const isLastVideo = () => store.numberOfVideos - 1 === store.index;

const bindSwipingListeners = ({ slidingContainer }) => {
  const handleMouseDown = (event) => {
    if (event && event?.target?.className === "native-dropdown") return;
    if (store.isSlidable) {
      initSwipeEvent({ event });
    }
  };

  const handleMouseMove = (event) => {
    if (store.touchEvent !== null && store.isSlidable) {
      store.dragY = event.clientY - store.touchEvent.startEvent.clientY;
      slidingContainer.childNodes.forEach((child) => {
        child.style.transition = "transform 0s";
        const transformValue = `translateY(${-store.index * child.offsetHeight + store.dragY}px)`;

        child.style.transform = transformValue;
      })
    }
  };

  const handleMouseUp = (event) => {
    if (store.touchEvent !== null) {
      handleSwipe({ event, slidingContainer });
    }
  };

  const handleTouchStart = (event) => {
    if (store.isSlidable) {
      initSwipeEvent({ event });
    }
  };

  const handleTouchMove = (event) => {
    if (store.touchEvent !== null && store.isSlidable) {
      store.dragY = event.changedTouches[0].screenY - store.touchEvent.startEvent.changedTouches[0].screenY;
      slidingContainer.childNodes.forEach((child) => {
        child.style.transition = "transform 0s";
        const transformValue = `translateY(${-store.index * child.offsetHeight + store.dragY}px)`;
        child.style.transform = transformValue;
      })
    }
  };

  const handleTouchEnd = (event) => {
    if (store.touchEvent !== null) {
      handleSwipe({ event, slidingContainer });
    }
  };

  if (!store.isTouchable) {
    slidingContainer.addEventListener("mousedown", handleMouseDown, { passive: true });
    slidingContainer.addEventListener("mousemove", handleMouseMove, true);
    slidingContainer.addEventListener("mouseup", handleMouseUp, { passive: true });
  } else {
    slidingContainer.addEventListener("touchstart", handleTouchStart, { passive: true });
    slidingContainer.addEventListener("touchmove", handleTouchMove, true);
    slidingContainer.addEventListener("touchend", handleTouchEnd, { passive: true });
  }

  // Return a cleanup function to remove the event listeners
  return () => {
    if (!store.isTouchable) {
      slidingContainer.removeEventListener("mousedown", handleMouseDown, true);
      slidingContainer.removeEventListener("mousemove", handleMouseMove, true);
      slidingContainer.removeEventListener("mouseup", handleMouseUp, true);
    } else {
      slidingContainer.removeEventListener(
        "touchstart",
        handleTouchStart,
        true
      );
      slidingContainer.removeEventListener("touchmove", handleTouchMove, true);
      slidingContainer.removeEventListener("touchend", handleTouchEnd, true);
    }
  };
};